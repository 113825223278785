<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('Course_Gategory') }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-1 d-none d-md-block"
          @click="isAddNewCourseGroup = true"
        >
          <v-icon
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon> {{ $t('add_course_category') }}
        </v-btn>
        <v-btn
          color="primary"
          class="me-1 d-block d-md-none"
          fab
          outlined
          icon
          @click="isAddNewCourseGroup = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model.trim="coursegroup_status_id"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.coursegroup_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.coursegroup_status_id`]="{ item }">
          <StatusBlock :status="item.coursegroup_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item;isEditCourseGroup = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>

          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="coursegroup_id = item.coursegroup_id;coursegroup_status_id_update = item.coursegroup_status_id;isStatusUpdate = true"
                v-on="on"
              >
                <v-icon>{{ item.coursegroup_status_id ==1? icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.coursegroup_status_id ==1? $t("suspend"):$t("normal") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="isStatusUpdate"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>  {{ coursegroup_status_id_update==1?$t('confirmCategoryCourseSuspend'):$t('cancelCategoryCourseSuspend') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processUpdateStatus"
            :disabled="processUpdateStatus"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isStatusUpdate = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddNewCourseGroup
      v-model="isAddNewCourseGroup"
      @onAdd="fetchDataTable"
    />
    <EditCourseGroup
      v-model="isEditCourseGroup"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
  </div>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiAlertOutline, mdiCheck,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useCourseGroup from './useCourseGroup'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewCourseGroup from './AddNewCourseGroup.vue'
import EditCourseGroup from './EditCourseGroup.vue'

export default {
  components: {
    StatusBlock,
    AddNewCourseGroup,
    EditCourseGroup,
  },
  setup() {
    const isAddNewCourseGroup = ref(false)
    const dataEdit = ref({})
    const isEditCourseGroup = ref(false)
    const {
      coursegroup_id,
      coursegroup_status_id_update,
      processUpdateStatus,
      isStatusUpdate,
      dataTableList,
      columns,
      loading,
      options,
      searchtext,
      statusList,
      coursegroup_status_id,
      fetchDataTable,
      updateStatus,
    } = useCourseGroup()

    return {
      coursegroup_id,
      coursegroup_status_id_update,
      processUpdateStatus,
      isStatusUpdate,
      dataEdit,
      isEditCourseGroup,
      isAddNewCourseGroup,
      dataTableList,
      columns,
      loading,
      options,
      searchtext,
      statusList,
      coursegroup_status_id,
      fetchDataTable,
      updateStatus,
      icons: {
        mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiAlertOutline, mdiCheck,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
