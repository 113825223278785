var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('Course_Gategory'))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"me-1 d-none d-md-block",attrs:{"color":"primary"},on:{"click":function($event){_vm.isAddNewCourseGroup = true}}},[_c('v-icon',{staticClass:"me-1"},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" "+_vm._s(_vm.$t('add_course_category'))+" ")],1),_c('v-btn',{staticClass:"me-1 d-block d-md-none",attrs:{"color":"primary","fab":"","outlined":"","icon":""},on:{"click":function($event){_vm.isAddNewCourseGroup = true}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)],1),_c('v-row',{staticClass:"px-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('search'),"outlined":"","dense":""},model:{value:(_vm.searchtext),callback:function ($$v) {_vm.searchtext=$$v},expression:"searchtext"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-select',{attrs:{"items":_vm.statusList,"label":_vm.$t('status'),"item-text":_vm.$i18n.locale,"outlined":"","dense":"","item-value":"value"},model:{value:(_vm.coursegroup_status_id),callback:function ($$v) {_vm.coursegroup_status_id=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"coursegroup_status_id"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.coursegroup_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.coursegroup_status_id",fn:function(ref){
var item = ref.item;
return [_c('StatusBlock',{attrs:{"status":item.coursegroup_status_id}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.dataEdit = item;_vm.isEditCourseGroup = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.coursegroup_id = item.coursegroup_id;_vm.coursegroup_status_id_update = item.coursegroup_status_id;_vm.isStatusUpdate = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.coursegroup_status_id ==1? _vm.icons.mdiDeleteOutline:_vm.icons.mdiCheck))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.coursegroup_status_id ==1? _vm.$t("suspend"):_vm.$t("normal")))])])]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"350"},model:{value:(_vm.isStatusUpdate),callback:function ($$v) {_vm.isStatusUpdate=$$v},expression:"isStatusUpdate"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-icon',{staticClass:"me-1",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAlertOutline)+" ")]),_vm._v(" "+_vm._s(_vm.coursegroup_status_id_update==1?_vm.$t('confirmCategoryCourseSuspend'):_vm.$t('cancelCategoryCourseSuspend'))+" "),_c('v-spacer')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","loading":_vm.processUpdateStatus,"disabled":_vm.processUpdateStatus},on:{"click":_vm.updateStatus}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.isStatusUpdate = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer')],1)],1)],1),_c('AddNewCourseGroup',{on:{"onAdd":_vm.fetchDataTable},model:{value:(_vm.isAddNewCourseGroup),callback:function ($$v) {_vm.isAddNewCourseGroup=$$v},expression:"isAddNewCourseGroup"}}),_c('EditCourseGroup',{attrs:{"data-edit":_vm.dataEdit},on:{"onUpdate":_vm.fetchDataTable},model:{value:(_vm.isEditCourseGroup),callback:function ($$v) {_vm.isEditCourseGroup=$$v},expression:"isEditCourseGroup"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }