import basicStatus from '@/@fake-db/data/basicStatus.json'
import courseGroup from '@/api/courseManagement/courseGroup'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function userCourseGroup() {
  const dataTableList = ref([])
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'coursegroup_id',
    },
    {
      text: i18n.t('category'),
      value: 'coursegroup_name',
      width: 600,
    },
    {
      text: i18n.t('status'),
      value: 'coursegroup_status_id',
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      sortable: false,
      width: 220,
      align: 'center',
    },
  ])
  const loading = ref(false)
  const options = ref({})

  const searchtext = ref('')
  const statusList = ref(basicStatus.data)
  const coursegroup_status_id = ref(1)

  const isStatusUpdate = ref(false)
  const processUpdateStatus = ref(false)
  const coursegroup_status_id_update = ref('')
  const coursegroup_id = ref('')

  const { courseGroupList, courseGroupStatusUpdate } = courseGroup

  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = () => {
    loading.value = true
    courseGroupList({
      searchtext: searchtext.value,
      coursegroup_status_id: coursegroup_status_id.value,
    }).then(res => {
      dataTableList.value = res
      loading.value = false
      options.value.itemsPerPage = -1
    })
  }

  const updateStatus = () => {
    processUpdateStatus.value = true
    courseGroupStatusUpdate({
      coursegroup_id: coursegroup_id.value,
      coursegroup_status_id: coursegroup_status_id_update.value == '1' ? '2' : '1',
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      isStatusUpdate.value = false
      fetchDataTable()
      processUpdateStatus.value = false
    })
  }

  watch([searchtext, coursegroup_status_id], () => {
    fetchDataTable()
  })

  return {

    coursegroup_id,
    coursegroup_status_id_update,
    processUpdateStatus,
    isStatusUpdate,
    dataTableList,
    columns,
    loading,
    options,
    searchtext,
    statusList,
    coursegroup_status_id,
    fetchDataTable,
    updateStatus,

  }
}
