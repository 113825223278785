<template>
  <div>
    <v-dialog
      v-model="isAddNewCourseGroup"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>{{ $t('add_course_category') }}</v-card-title>
        <v-form
          ref="formAddCourseGroup"
          @submit.prevent="createCourseGroup"
        >
          <v-card-text>
            <v-text-field
              v-model="shop_name"
              readonly
              outlined
              dense
              :label="$t('clinic_name')"
            ></v-text-field>
            <v-text-field
              v-model="coursegroup_name"
              :rules="[required]"
              autofocus
              :label="$t('course_category')"
              class="mt-3"
              outlined
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-course-group',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import courseGroup from '@/api/courseManagement/courseGroup'
import store from '@/store'

export default {

  model: {
    prop: 'isAddNewCourseGroup',
    event: 'update:is-add-new-course-group',
  },
  props: {
    isAddNewCourseGroup: Boolean,
  },
  setup(props, { emit }) {
    const coursegroup_name = ref('')
    const shop_name = (localStorage.getItem('shop_name'))
    const formAddCourseGroup = ref(null)
    const loading = ref(false)

    const { courseGroupAdd } = courseGroup

    const createCourseGroup = () => {
      const isFormValid = formAddCourseGroup.value.validate()
      if (!isFormValid) return
      loading.value = true
      courseGroupAdd({
        coursegroup_name: coursegroup_name.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',

        })
        loading.value = false
        emit('update:is-add-new-course-group', false)
        coursegroup_name.value = ''
        emit('onAdd')
      })
    }

    watch(() => props.isAddNewCourseGroup, newVal => {
      if (newVal) {
        coursegroup_name.value = ''
      }
    })

    return {
      coursegroup_name,
      shop_name,
      formAddCourseGroup,
      createCourseGroup,
      loading,
      required,

    }
  },
}
</script>

<style lang="scss" scoped>

</style>
